import { NavLink } from "react-router-dom";
import { news } from "../data/bec";
import { useState } from "react";

export function News(params) {
  const [first, setfirst] = useState(3)
  let arq=[];


  function massFil(params) {
     
     if (first===false) {
      for (let i = 0; i < news.length; i++) {
        arq[i]= news[i];
        
       }
     }else{
      for (let i = 0; i < first; i++) {
        arq[i]= news[i];
        
       }
     }
 }
 massFil()
  return (
    <section id="news" className="news">
      <div className="container">
        <h2>
          Новости
          нашей компании
        </h2>
        <p>
        Будьте всегда в курсе событий
        </p>
        <div className="news-body">
          <div className="news-body_list">
            {
                arq.map((e,i)=>(
                    <NewsElement key={i} id={i} img={e.img} date={e.data} text={e.text} />
                ))
            }
          </div>
          {first !== false ? (
              <div className="katalog-list_button">
                <button
                  className="header-right__button"
                  onClick={() => setfirst(false)}
                >
                  <span>Показать все</span>
                </button>
              </div>
            ) : (
              ""
            )}
        </div>
       {/* <div className="news_button_box">
       <button className="news_button but">
        все новости
        </button>
       </div> */}
      </div>
    </section>
  );
}
export function NewsElement({img,date,text,id}) {
  return <NavLink to={`/news/${id}`} className="news-item" style={{backgroundImage:`url(${img})`}}>
    {/* <img src={process.env.PUBLIC_URL + img} alt={"Новости компании от "+date} /> */}
    <div className="news-item_content">
    <p className="news-item_date">{date}</p>
        <p className="news-item_text">
            {text}
        </p>
        
    </div>
  </NavLink>;
}
