import { Gallari } from "../bloc/gallari";
import { Info } from "../bloc/info";
import { Katalog } from "../bloc/katalog";
import { LineKlient } from "../bloc/lineKlient";
import { News } from "../bloc/naws";
import { Opros } from "../bloc/opros";
import { Slider } from "../bloc/slider";
import { StagesWork } from "../bloc/stagesWork";
import "../css/main.css"

export function Main(params) {
        document.title = 'СпецАвто';

    return(
        <main>
            <Slider/>
            <Katalog/>
            <Gallari/>
            {/* <Opros fos={params.fos} setfos={params.setfos}/> */}
            <StagesWork/>
            <News fos={params.fos} setfos={params.setfos}/>
            <LineKlient/>
        </main>
    )
}