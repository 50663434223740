export function Gallari(params) {
    return(
        <section className="gallari">
            <div className="container">
                <h2>
                Автомобили
нашего модельного ряда
                </h2>
                <div className="gallari_items">
                    <div className="gallari_item">
                    <img src={process.env.PUBLIC_URL + "/img/gallari/1.jpg"} alt="топливозаправщик 362233 (уаз)" />
                        <h3 className="gallari_item_name">
                        топливозаправщик 362233 (уаз)
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    )
}