export function StagesWork(params) {
  return (
    <section className="stagesWork">
      <div className="container">
        <h2>
          этапы работы
          нашей компании
        </h2>
        <div className="stagesWork-items">
          <div className="stagesWork-item">
            <div data-type="01" className="stagesWork-item_number"> <h3>Входящее обращение</h3></div>
            <div className="stagesWork-item_text">
              
              <p>

              Оставьте заявку на сайте или позвоните нам по номеру телефона +7 (960) 390 - 25 - 44  </p>
            </div>
          </div>
          <div className="stagesWork-item">
            <div data-type="02" className="stagesWork-item_number"><h3>Подбор техники</h3></div>
            <div className="stagesWork-item_text">
              
              <p>
              Уточнение вида техники, характера работ и места их проведения
              </p>
            </div>
          </div>
          <div className="stagesWork-item">
            <div data-type="03" className="stagesWork-item_number"><h3>Заключение договора</h3></div>
            <div className="stagesWork-item_text">
              
              <p>
                Составляем договор, в котором прописаны условия
                сотрудничества
              </p>
            </div>
          </div>
          <div className="stagesWork-item">
            <div data-type="04" className="stagesWork-item_number"><h3>Исполнение работ</h3></div>
            <div className="stagesWork-item_text">
              
              <p>
              Вы  вносите  предоплату  и  после мы 
исполняем работы согласно договору
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
