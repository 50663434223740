import { Link, NavLink, useLocation } from "react-router-dom";
import "../css/footer.css";
import { data } from "../data/bec";
import { useSelector } from "react-redux";
import { todosSelector } from "../stor/selectors";
import { useState } from "react";
export function Footer(params) {
  const [first, setfirst] = useState(1);
  const data = useSelector(todosSelector);
  let location =useLocation()
  const parts =[]= location.pathname.split("/");
  
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_left">
          <Link className="footer_logo" to="/">
            <img
              src={process.env.PUBLIC_URL + "/logoGr.png"}
              alt="Спецавто Логотип"
            />
          </Link>
          <p className="header_slogan">
            <span>ПРОИЗВОДСТВО</span> <br /> СПЕЦАВТОМОБИЛЕЙ и ПРИЦЕПОВ
          </p>
        </div>
        <div className="footer_right">
          <div>
            <div>
              <h3 onClick={() => setfirst(1)}>Спецавтомобили УАЗ</h3>
              <nav
                className={
                  first === 1 ? "footer_right_nav active" : "footer_right_nav"
                }
              >
                {data.all &&
                  data.all.map((e, i) => (
                    <NavLink
                      to={`/katalog/uaz/${e.translit}`}
                      key={i}
                      className="footer-list_items_link"
                    >
                      {e.class + " "}
                      {e.cat} - {e.model}
                    </NavLink>
                  ))}
              </nav>
            </div>
          </div>
          <div className="mapSite">
            <a href="/news">Новости</a>
            <Link to="/kontact">Контакты</Link>
            <Link to="/kompani">О компании</Link>
          </div>
          <div className="mapSite">
            <Link to="/kontact">Контакты</Link>
            <div className="ad">
              <span>Адрес:</span>
              <a href="/kontact">
                432045, Россия, г. Ульяновск, Московское шоссе, д. 3, офис  224
              </a>
            </div>
            <div className="ad">
              <span>Телефон:</span>
              <a href="tel:+79603721993">+7 (960) 372-19-93</a>
            </div>
            <div className="ad">
              <span></span>
              <a href="tel:+79603902544">+7(960) 390-25-44</a>
            </div>
            <div className="ad">
              <span>Почта:</span>
              <a href="mailto:mail@specavto.com">mail@specavto.com</a>
            </div>
          </div>
        </div>

        {/* <nav className="footer-menu">
          <Link className="footer-menu_link" to="/">
            О КОМПАНИИ
          </Link>
          <Link className="footer-menu_link" to="/">
            НОВОСТИ
          </Link>
          <Link className="footer-menu_link" to="/kontact">
            Контакты
          </Link>
          <div className="footer-menu_contact_box">
            <a className="footer-menu_contact" href="https://vk.com/specavto73">
              <img
                src={process.env.PUBLIC_URL + "/img/vk.svg"}
                alt="Наша группа В Контакте"
              />
            </a>
            <a className="footer-menu_contact" href="/">
              <img
                src={process.env.PUBLIC_URL + "/img/tg.svg"}
                alt="Наш канал в Telegramm"
              />
            </a>
            <a href="/" className="footer-menu_contact">
              <img
                src={process.env.PUBLIC_URL + "/img/watsap.svg"}
                alt="Связаться с нами в WatsApp"
              />
            </a>
            <button onClick={()=>params.setfos(!params.fos)} className="header-right__button">
              <img src={process.env.PUBLIC_URL + "/img/mail.svg"} alt="mail" />
              <span>НАПИСАТЬ НАМ</span>
            </button>
          </div>
        </nav>
        <div className="footer-list">
            <div className="footer-list_item">
                <h4>
                УАЗ
                </h4>
                <div className="footer-list_items">
                    {data.all&&
                    data.all.map((e,i)=>(
                      <NavLink to={`/katalog/uaz/${e.translit}`}  key={i} className="footer-list_items_link">
                        {e.class + " "}<span className="orange">{e.cat} - </span> {e.model}
                      </NavLink>
                  ))}
                </div>
            </div>
        </div> */}
      </div>
    </footer>
  );
}
