import { Katalog } from "../bloc/katalog";


export function Katalogs() {
    // из-за того что роутинг происходит посредством прорисовки новых элементов а не переходом на новую страницу тайтл на разнае страницы прописывать можно так
    document.title = 'СпецАвто-Каталог продукции';

    return(
        <main>
            <Katalog/>
        </main>
    )
}