import { BreadCrumbs } from "../bloc/breadСrumbs";
import { LineKlient } from "../bloc/lineKlient";
import "../css/kompani.css";

export function Kompani(params) {
  return (
    <>
      <main className="kompani">
        <div className="container">
          <BreadCrumbs />
          <section>
            <h1>О КОМПАНИИ</h1>
            <div className="kompani_info">
              <img src={process.env.PUBLIC_URL + "img/kompani3.jpg"} alt="" />
              <div className="kompani_info_text">
                <h2>
                  Предприятие ООО "Спецавто" - ваш надежный и проверенный
                  партнер в сфере производства спецавтомобилей и прицепов с 1993
                  года
                </h2>
                <p>
                  Мы гордимся тем, что за это время заключили более <b>5000</b>{" "}
                  успешных договоров и стали одним из значимых предприятий в
                  нашей отрасли.
                </p>
                <p>
                  <b> Мы являемся официальными доработчиками</b> завода УАЗ, что
                  подтверждает нашу компетентность и профессионализм.
                </p>
                <p>
                  Одной только Россией география продаж автомобилей не
                  ограничивается: помимо внутреннего рынка, машины уходят в
                  города Ближнего и Дальнего зарубежья.
                </p>
                <p>
                  
                </p>
                <p className="gen">
               <i>
               Генеральный директор  <br />
               <b>Костяев Михаил Вячеславович</b>
               </i>
                </p>
              </div>
            </div>
          </section>
          <section className="kompani_advantages">
            <h2>ПРЕИМУЩЕСТВА</h2>
            <div className="kompani_advantages_box">
              <Advantages
                title="Широкий ассортимент"
                text="
                    За свою тридцатилетнюю историю компания ООО «Спецавто» готова предложить вам широкий спектр автоспецтехники, который полностью соответствуют самым высоким требованиям. Вся наша продукция проходит строгий контроль качества и безопасности, что позволяет вам быть полностью уверенными в их надежности и долговечности.
                    Мы рассмотрим любое предложение заказчика и беремся за изготовление самых нестандартных вариантов автоспецтехники.
                    "
              />
              <Advantages
                title="Надежные партнеры"
                text="Мы являемся надежным партнером, на которого вы можете положиться. Когда дело касается гарантийных обязательств, мы всегда берем это на себя. Если у вас возникнет проблема во время использования спецавтомобиля, наша команда специалистов гарантирует оперативное решение этой проблемы. Мы понимаем, насколько важно для вас сохранить бесперебойную работу вашей техники, поэтому прикладываем все усилия, чтобы устранить неисправности в кратчайшие сроки."
              />
              <Advantages
                title="Система оплаты"
                text="
                Кроме того, мы предлагаем гибкую систему оплаты и финансирования, чтобы сделать приобретение спецтехники максимально удобным и выгодным для вас. Наша команда экспертов всегда готова помочь вам выбрать оптимальное решение, учитывая ваши индивидуальные потребности и бюджет. Мы сделаем все возможное, чтобы вы остались довольны сотрудничеством с нами.
                "
              />
            </div>
            <p className="kompani_advantages_ps">

            Мы гордимся доверием наших заказчиков и стремимся стать вашим надежным партнером на долгие годы. <br /> Мы надеемся, что наш опыт и знания будут полезны Вам!<br />
Обратившись в ООО «Спецавто», вы можете быть уверены, что получите лучшую автоспецтехнику,<br /> которая полностью соответствует вашим требованиям и ожиданиям.
            </p>
          </section>
          <LineKlient/>

        </div>
      </main>
    </>
  );
}

function Advantages(params) {
  return (
    <div className="advantages">
      <h3>{params.title}</h3>
      <p>{params.text}</p>
    </div>
  );
}
