import { useLocation, useParams } from "react-router-dom";
import { BreadCrumbs } from "../bloc/breadСrumbs";
import "../css/avto.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { todosSelector } from "../stor/selectors";
import { spacifics } from "../stor/thunk";
import Preloader from "../bloc/preloader";

export function Avto(pars) {
  const link = useLocation();

  const params = useParams();
  const [arr, setarr] = useState(false);
  const [imgs, setimgs] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector(todosSelector);
  useEffect(() => {
    dispatch(spacifics({ translit: params.name }));
  }, [arr]);

  useEffect(() => {
    if (data.specific) {
      setimgs(data.specific.galery[0]);
      document.title = "СпецАвто - " + data.specific.name;
    }
  }, [data.specific]);
 
  useEffect(() => {
    // console.log(link);
    // window.location.reload();
  }, []);
  
  if (!data.specific) {
    setTimeout(lod, 1000);
    function lod(params) {
      setarr(!arr);
    }
    return <Preloader/>;
  }

  function foses() {
    pars.setfomName(data.specific.name);
    pars.setformImg(imgs);
    pars.setfos(true);
  }
  return (
    <main itemID={params.name}>
      <div className="container">
        <BreadCrumbs />

        <div className="avto_body">
          <div className="avto_body_gallari">
            <div className="avto_body_gallari_prev">
              <img
                src={process.env.PUBLIC_URL + `/img/katalog/galery/${imgs}`}
                alt=""
              />
            </div>
            <div className="avto_body_gallari_lists">
              {data.specific &&
                data.specific.galery.map((e, i) => (
                  <div
                    onClick={() => setimgs(e)}
                    key={i}
                    className="avto_body_gallari_list"
                  >
                    <img
                      src={process.env.PUBLIC_URL + `https://specavto.agatech.ru//img/katalog/galery/${e}`}
                      alt=""
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="avto_body_info">
            <h1 className="avto_title">{data.specific.class}</h1>
            <div
              className="avto_opis"
              dangerouslySetInnerHTML={{ __html: data.specific.max_text }}
            ></div>
            <ul className="avto_opis_list">
              {data.specific &&
                data.specific.param.map(
                  (e, i) =>
                    e.id !== "31" && (
                      <li className="a1" key={i}>
                        <div>
                          {e.title + " " + (e.unit !== null ? e.unit : "")}
                        </div>
                        <div>{e.value}</div>
                      </li>
                    )
                )}
                {data.specific &&
                data.specific.param.map(
                  (e, i) =>
                    e.id === "31" && (
                      <li className="a1" key={i}>
                        <div>
                          {e.title + " " + (e.unit !== null ? e.unit : "")}
                        </div>
                        <div>{e.value}</div>
                      </li>
                    )
                )}
                
            </ul>
            <button onClick={foses} className="news_button but">
              {" "}
              узнать цену{" "}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}
