import { Link, NavLink, useParams } from "react-router-dom";
import { news } from "../data/bec.js";
import { BreadCrumbs } from "../bloc/breadСrumbs.jsx";
import "../css/newsPages.css"
import { useState } from "react";
export default function NewsPages() {
  const params = useParams();
  const [first, setfirst] = useState(null)
  console.log(params);
  let id = params.id;


  function open(id) {
    setfirst(id);
    
  }


  
  return (
    <main className="newsPages">
      <div className="container">
        <section>
        <div className="BreadCrumbs">
            <NavLink to="/" className="breadCrumbs_link">
              <img src={process.env.PUBLIC_URL + "/img/home.png"} alt="" />
            </NavLink>
            <Link to="/news" className="breadCrumbs_link">
              Новости
            </Link>
          </div>
        <h1>{news[id].text}</h1>
        <div className="newsPages_body">
            <div>
                <p className="newsPages_textBig" dangerouslySetInnerHTML={{ __html: news[id].textBig }}>
                </p>
            </div>
            <div>
                <p className="newsPages_dates">
                <b>{news[id].data}</b>
                </p>
                <img className="newsPages_prev" src={process.env.PUBLIC_URL + news[id].img} alt="" />
            </div>
        </div>
        </section>
       {news[id].imgGallari!==undefined?
        
        <section>
        <h2>Фотогалерея</h2>
        <div className="newsPages_gallari">
            {news[id].imgGallari.map((el,id) =>(
                <img onClick={()=>open(process.env.PUBLIC_URL + el)} key={id} src={process.env.PUBLIC_URL + el} alt="" />
            ))

            }
        </div>
    </section>:""
       }
      </div>
      <Popup id={first} setfirst={setfirst}/>
    </main>
  );
}
function Popup({id,setfirst}) {
    return(
        <div onClick={()=>setfirst(null)} className={id?"popup_img active":"popup_img "}>
            <div >
            <img  src={id} alt="" />
            </div>
        </div>
    )
}
