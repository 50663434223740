export const data = {
  yas: [
    {
      name: "Пожарный автомобиль",
      marka: "36222",
      img: "/img/katalog/none.png",
      param: {},
      gallari: [],
    },
    {
      name: "Хлебовоз",
      marka: "290513",
      img: "/img/katalog/1.png",
      param: {},
      gallari: [],
    },
    {
      name: "Автолавка",
      marka: "290513",
      img: "/img/katalog/2.png",
      param: {},
      gallari: [],
    },
    {
      name: "Пожарный автомобиль",
      marka: "362223",
      img: "/img/katalog/3.png",
      param: {},
      gallari: [],
    },
    {
      name: "Молоковоз",
      marka: " 362213",
      img: "/img/katalog/4.png",
      param: {},
      gallari: [],
    },
    {
      name: "Молоковоз",
      marka: "36221",
      img: "/img/katalog/5.png",
      param: {},
      gallari: [],
    },
    {
      name: "Топливозаправщик",
      marka: "36223",
      img: "/img/katalog/6.png",
      param: {},
      gallari: [],
    },
    {
      name: "Топливозаправщик",
      marka: "362233",
      img: "/img/katalog/7.png",
      param: {},
      gallari: [],
    },
    
  ],
  gaz: {},
  pricep: {},
};
export const news=[
  {
    img:"/img/news/11.jpg",
    text:"В октябре 2022г. ООО «Спецавто» представило новые машины – «Рыбовозы».",
    textBig:"<span>Автоцистерны для перевозки живой рыбы – это пищевые автоцистерны, дополнительно оборудованные системой обогащения воды кислородом и освобождения от углекислоты и хлора путем продувания воздуха для поддержания жизнеспособности рыбы во время ее транспортировки.</span> <br>"+
    "<span>Автоцистерны-рыбовозы имеют термоизоляцию. Загрузка живой рыбы в цистерну производится через верхний загрузочный люк, выгружается рыба через них же сачками или же через разгрузочный люк в задней стенке цистерны.</span> <br>"+
    
    "<span>Если Вы планируете заниматься перевозкой живой рыбы на длинные и короткие расстояния для продажи или выращивания, то ООО «Спецавто» изготовит для Вас спецавтомобиль «Рыбовоз».</span> <br>",
    data:"03/11/2022",
    imgGallari:[
      "/img/news/11.jpg",
      "/img/news/gallari/11.1.jpg",
      "/img/news/gallari/11.2.jpg",
      "/img/news/gallari/11.3.jpg",
      "/img/news/gallari/11.4.jpg",
    ]
  },
  {
    img:"/img/news/gallari/1.3.jpg",
    text:"На днях наша компания ООО «Спецавто» отправила три Пожарные машины в Казахстан.",
    textBig:"Теперь они верой и правдой будут служить во благо общества!",
    data:"28/06/2021",
    imgGallari:[
      "/img/news/gallari/1.3.jpg",
      "/img/news/5.jpg",
      "/img/news/gallari/1.2.jpg",
      "/img/news/gallari/1.4.jpg",
      "/img/news/gallari/1.5.jpg",
      "/img/news/gallari/1.6.jpg",
      "/img/news/gallari/1.7.jpg",

    ]
  },
  {
    img:"/img/news/14.jpg",
    text:"ООО «Спецавто» предлагает бензовоз в новом исполнении - «Передвижная автозаправочная станция» (ПАЗС).",
    textBig:"<span>Данный автомобиль ПАЗС предназначен для транспортирования и дозированного отпуска светлых нефтепродуктов: бензин, дизельное топливо, керосин (плотностью не более 0,86 г/см3).</span> <br>"+
    "<span>Отпуск топлива осуществляется по лимитным схемам с идентификацией получателя топлива, с использованием пластиковых карт (бесконтактная карта Mifare или Em-Marine), с возможностью объединения карт в группы, с организацией контрольных форм.</span> <br>"+
    "<span>Автомобиль может быть выполнен на базе автомобилей УАЗ-236022, ГАЗ-3302, ГАЗ-А21R23, ГАЗ-А21R22.</span> <br>"+
    "<span>Автомобиль может быть выполнен на базе автомобилей УАЗ-236022, ГАЗ-3302, ГАЗ-А21R23, ГАЗ-А21R22.</span> <br>"+
    "<span>Вот несколько преимуществ а/м  «ПАЗС»:</span> <br>"+
    "<ul>"+
    "<li>быстрое развертывание в удаленных районах</li>"+
    "<li>точная выдача топлива без перелива и недолива с автоматическим учетом каждой заправки</li>"+
    "<li>удаленный контроль за количеством топлива</li>"+
    "</ul>"+
    "<span>Подобрать комплектацию, ознакомиться с ценами и сделать заказ Вы можете, обратившись в нашу организацию.</span> <br>",

    
    data:" 25/06/2021",
    imgGallari:[
      "/img/news/14.jpg",
      "/img/news/gallari/14.1.jpg",
      "/img/news/gallari/14.2.jpg",
      "/img/news/gallari/14.3.jpg",
      "/img/news/gallari/14.4.jpg",
    ]
  },
  {
    img:"/img/news/gallari/2.7.jpg",
    text:"Дезинфектор на базе УАЗ Профи",
    textBig:"<span> И вновь мы готовы представить новый а/м 362243 «Дезинфекционная установка» на базе УАЗ ПРОФИ.</span>  <br/>"+

    "<span>Такой а/м необходим не только при проведении ветеринарно-санитарных мероприятий в животноводческих помещениях, дезинфекции на складах, в зернохранилищах и овощехранилищах, но и для химической защиты садов, виноградников, ягодников от вредителей и болезней.</span>  <br/>"+
    
    "<span> А еще такая установка может использоваться для тушения небольших очагов возгорания и помывки сельхозтехники.</span> ",
    data:"03/07/2020",
    imgGallari:[
      "/img/news/gallari/2.7.jpg",

      "/img/news/gallari/2.2.jpg",
      "/img/news/gallari/2.3.jpg",
      "/img/news/gallari/2.4.jpg",
      "/img/news/gallari/2.5.jpg",
      "/img/news/gallari/2.6.jpg",
      "/img/news/2.jpg",


    ]
  },
  
  {
    img:"/img/news/3.jpg",
    text:"Участие в ПРОГРАММЕ VII Международного Форума-выставки производителей товаров и услуг",
    textBig:'23-25 апреля 2021г. ООО "Спецавто" приняло участие в выставке-форуме "Сделано в Ульяновской области - 2021". Это стало отличной возможностью найти партнеров и заказчиков, в том числе из других регионов и стран. А также познакомить жителей региона со своей продукцией и повысить узнаваемость бренда.</span> ',
    data:"03/06/2021",
    imgGallari:[
      "/img/news/3.jpg",
      "/img/news/gallari/3.1.jpg",
      "/img/news/gallari/3.2.jpg",
      "/img/news/gallari/3.3.jpg",
      "/img/news/gallari/3.4.jpg",
    ]
  },
  {
    img:"/img/news/12.jpg",
    text:"ООО «Спецавто» представляет новую модель Пожарной машины на базе ГАЗель Next",
    textBig:"<span>Этим летом разработан и произведен пожарный автомобиль на базе ГАЗель Next для выполнения аварийно-спасательных работ, связанных с тушением пожаров. Наш Пожарный автомобиль впервые выпущен с уникальной системой поддержания свинцовых аккумуляторов в оперативной готовности – «Капель-2» .</span> <br>"+
    "<span>Система позволяет непосредственно на автотранспорте:</span> <br>"+
    "<ul>"+
    "<li>заряжать новую АКБ</li>"+
    "<li>поддерживать АКБ автотранспорта в состоянии постоянной готовности к применению.</li>"+
    "</ul>",
    
    data:"10/09/2019",
    imgGallari:[
      "/img/news/12.jpg",
      "/img/news/gallari/12.1.jpg",
      "/img/news/gallari/12.2.jpg",
      "/img/news/gallari/12.3.jpg",
      "/img/news/gallari/12.4.jpg",
      "/img/news/gallari/12.5.jpg",
      "/img/news/gallari/12.6.jpg",
      "/img/news/gallari/12.7.jpg",
    ]
  },
  {
    img:"/img/news/13.jpg",
    text:"Топливозаправщик на базе УАЗ Профи.",
    textBig:"<span>На сегодняшний день линейка автомобилей для перевозки светлых нефтепродуктов пополнилась новыми моделями на базе УАЗ-ПРОФИ. Новые версии коммерческого автомобиля отличаются комплектацией и исполнением цистерны.</span> <br>"+
    "<span>Ключевыми преимуществами новых версий «Топливозаправщика» являются грузоподъёмность, комфорт легкового автомобиля и, конечно же, проходимость на уровне других моделей УАЗ. Бензовозы по габаритам не отличаются от базовой версии. Общий объём автоцистерны 1500 литров. Обе модификации доступны как в полноприводном, так и в заднеприводном исполнениях.</span> <br>",
    
    data:"05/07/2019",
    imgGallari:[
      "/img/news/13.jpg",
      "/img/news/gallari/13.1.jpg",
      "/img/news/gallari/13.2.jpg",
      "/img/news/gallari/13.3.jpg",
      "/img/news/gallari/13.4.jpg",
    ]
  },

]