import { useState } from "react";
import { BreadCrumbs } from "../bloc/breadСrumbs";
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";

export function Kontact() {
  const [recvizit, setrecvizit] = useState(false)
  const first = [54.29221, 48.277682];
  const mapState = { center: [54.29221, 48.277682], zoom: 17 };
  const [tab, setTAb] = useState(0);
  return (
    <main className="kontact">
      <div className="container">
        <BreadCrumbs />
        <h1>контакты</h1>
        <div className="kontact_info">
          <div className="kontact_info_contacts">
            <div className="kontact_info_contacts_min">
              <h3>Телефон:</h3>
              <a className="kontact_info_contacts_tel" href="tel:+79063902544">
                + 7 (906) 390-25-44
              </a><br />
              <a className="kontact_info_contacts_tel" href="tel:+79603721993">+7 (960) 372-19-93</a>

            </div>
            <div className="kontact_info_contacts_min">
              <h3>Режим работы:</h3>
              <p className="kontact_info_text">Пн - Пт с 9:00 до 17:00</p>
              <p className="kontact_info_text">Сб - Вс выходной</p>
            </div>
            
            <div className="kontact_info_contacts_min">
              <h3>Адрес:</h3>
              {/* https://yandex.ru/maps/?um=constructor%3A038268c21fdcf0e362ee772a23d85a31610d333c261f8bc6c80cbeb4b9f569c7&source=constructorLink */}
              <p className="kontact_info_text">
                432045, Россия, Ульяновская обл., г.Ульяновск, Московское
                шоссе, д.3, офис 224
              </p>
            </div>
            <div className="kontact_info_contacts_min">
              <h3 onClick={()=>setrecvizit(!recvizit)} className={recvizit?"kontact_rec_head active":"kontact_rec_head"}>
                Банковские реквизиты{" "}
                
                <a download="реквизиты.pdf" title="Банковские реквизиты+"  href="./files/реквизиты.pdf" target="_blank" rel="noopener noreferrer">
                скачать{" "}
            </a>
              </h3>
              <ul className={recvizit?"kontact_rec active":"kontact_rec"}>
                <li>
                  <div>Р/с:</div> <span>40702810769000011806</span>
                </li>
                <li>
                  <div>Банк:</div>{" "}
                  <span>
                    Ульяновское отдел. №8588 ПАО СБЕРБАНК г. Ульяновск
                  </span>
                </li>
                <li>
                  <div>К/с:</div> <span>30101810000000000602</span>
                </li>
                <li>
                  <div>БИК:</div> <span>047308602</span>
                </li>
                <li>
                  <div>ОГРН:</div> <span>1027301489720</span>
                </li>
                <li>
                  <div>ИНН/КПП:</div> <span>7327025655/ 732701001</span>
                </li>
                <li>
                  <div>Юр. адрес:</div>{" "}
                  <span>
                    432045, Россия, Ульяновская обл., г. Ульяновск, Московское
                    шоссе, д.3, кв. 224
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <YMaps className="map">
            <Map height={"900px"} width={"100%"} defaultState={mapState}>
              <Placemark
                geometry={first}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: process.env.PUBLIC_URL + "/map.png",
                  iconImageSize: [70, 70],
                  iconImageOffset: [-53, -42],
                }}
              />
            </Map>
          </YMaps>
          {/* <script type="text/javascript" charset="utf-8" async src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A038268c21fdcf0e362ee772a23d85a31610d333c261f8bc6c80cbeb4b9f569c7&amp;width=1280&amp;height=720&amp;lang=ru_RU&amp;scroll=true"></script> */}
        </div>

        <div className="kontact_tel">
          <div>
            <div className={tab===1?"kontact_tel_min active":"kontact_tel_min"}>
              <h3 onClick={() => setTAb(1)}>Генеральный директор</h3>
              <div
                className={
                  tab === 1 ? "kontact_contact active" : "kontact_contact"
                }
              >
                <br />
                <p className="kontact_tel_min_name">
                  Костяев Михаил Вячеславович
                </p>
                <br />
                <a className="kontact_tel_min_tel" href="tel:+78422688495">
                  <b>Телефон: </b>+ 7 (8422) 688-495
                </a>
                <br />
                <a
                  className="kontact_tel_min_mail"
                  href="mailto:mail@specavto.com"
                >
                  <b>E-mail: </b>mail@specavto.com
                </a>
                <span onClick={() => setTAb(0)} className="bec">
                  Свернуть
                </span>
              </div>
            </div>
            <div className={tab===2?"kontact_tel_min active":"kontact_tel_min"}>
              <h3 onClick={() => setTAb(2)}>Коммерческий директор</h3>
              <div
                className={
                  tab === 2 ? "kontact_contact active" : "kontact_contact"
                }
              >
                <br />
                <p className="kontact_tel_min_name">Салий Светлана Ронисовна</p>
                <br />
                <a className="kontact_tel_min_tel" href="tel:+78422688495">
                  <b>Телефон: </b>+ 7 (906) 390-25-44
                </a>
                <br />
                <a className="kontact_tel_min_tel" href="tel:+79063606280">
                  <b>Телефон: </b>+ 7 (906) 360-62-80
                </a>
                <br />
                <a className="kontact_tel_min_tel" href="tel:+78422688495">
                  <b>Телефон: </b>+ 7 (8422) 688-495
                </a>
                <br />

                <a
                  className="kontact_tel_min_mail"
                  href="mailto:mail@specavto.com"
                >
                  <b>E-mail: </b>mail@specavto.com
                </a>
                <span onClick={() => setTAb(0)} className="bec">
                  Свернуть
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className={tab===3?"kontact_tel_min active":"kontact_tel_min"}>
              <h3 onClick={() => setTAb(3)}>Отдел продаж</h3>
              <div
                className={
                  tab === 3 ? "kontact_contact active" : "kontact_contact"
                }
              >
                
                <br />
                <a className="kontact_tel_min_tel" href="tel:+79603721993">
                  <b>Телефон: </b>+ 7 (960) 372-19-93
                </a>
                <br />
                <a className="kontact_tel_min_tel" href="tel:+79063902544">
                  <b>Телефон: </b>+ 7 (906) 390-25-44
                </a>
                <br />
                <a className="kontact_tel_min_tel" href="tel:+78422688495">
                  <b>Телефон: </b>+ 7 (8422) 688-495
                </a>
                <br />
                <a
                  className="kontact_tel_min_mail"
                  href="mailto:mail@specavto.com"
                >
                  <b>E-mail: </b>mail@specavto.com
                </a>
                <span onClick={() => setTAb(0)} className="bec">
                  Свернуть
                </span>
              </div>
            </div>
            <div className={tab===4?"kontact_tel_min active":"kontact_tel_min"}>
              <h3 onClick={() => setTAb(4)}>Специалист по работе с претензиями</h3>
              <div
                className={
                  tab === 4 ? "kontact_contact active" : "kontact_contact"
                }
              >
                <br />
                <p className="kontact_tel_min_name">Пашигин Вадим Юрьевич</p>
                <br />
                <a className="kontact_tel_min_tel" href="tel:+79053492400">
                  <b>Телефон: </b>+ 7 (905) 349-24-00
                </a>
                <br />
                <a className="kontact_tel_min_tel" href="tel:+78422688495">
                  <b>Телефон: </b>+ 7 (8422) 688-495
                </a>
                <br />
                

                <a
                  className="kontact_tel_min_mail"
                  href="mailto:mail@specavto.com"
                >
                  <b>E-mail: </b>mail@specavto.com
                </a>
                <span onClick={() => setTAb(0)} className="bec">
                  Свернуть
                </span>
              </div>
            </div>
          </div>
          <div>
          <div className={tab===5?"kontact_tel_min active":"kontact_tel_min"}>
              <h3 onClick={() => setTAb(5)}>Бухгалтерия</h3>
              <div
                className={
                  tab === 5 ? "kontact_contact active" : "kontact_contact"
                }
              >
                
                <br />
                <a className="kontact_tel_min_tel" href="tel:+78422655755">
                  <b>Телефон: </b>+ 7 (8422) 655-755
                </a>
                <br />
                <a
                  className="kontact_tel_min_mail"
                  href="mailto:bukspecavto@yandex.ru"
                >
                  <b>E-mail: </b>bukspecavto@yandex.ru
                </a>
                <span onClick={() => setTAb(0)} className="bec">
                  Свернуть
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
